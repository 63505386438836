<template>
  <div class="paddingTopPage60 centre" v-if="this.frateTrovato">
    <ToolbarTop url="/annuario" backLabel="Lista Annuario"></ToolbarTop>
    <div>
      <div>
        <img :src="image" width="100" />
      </div>
      <div class="nomeCognome">{{ this.nomeCognome }}</div>
      <div class="center">{{ this.titoloGiurisdizione }}</div>
      <div class="center gras ital" v-if="this.funzione">{{ this.funzione }}</div>
      <div class="cardYellow mt15">
        <div class="testoNormale"><strong>E-mail:</strong>&nbsp;&nbsp;{{ this.email }}</div>
        <div class="testoNormale"><strong>Cellulare:</strong>&nbsp;&nbsp;{{ this.cellulare }}</div>
        <div class="testoNormale" v-if="this.curriculum"><strong>Curriculum:</strong>&nbsp;&nbsp;{{ this.curriculum }}</div>
        <div class="testoNormale" v-if="this.inCorso"><strong>In corso:</strong>&nbsp;&nbsp;{{ this.inCorso }}</div>
      </div>
      <div class="cardWhite borderBottom">
        <div class="testoNormale"><strong>Nato il </strong>{{ this.dataNascita }} a {{ this.luogoNascita }}</div>
        <div class="testoNormale"><strong>Onomastico:</strong>&nbsp;&nbsp;{{ this.onomastico }}</div>
        <div class="testoNormale" v-if="this.profSemplice"><strong>Prof. semplice:</strong>&nbsp;&nbsp;{{ this.profSemplice }}</div>
        <div class="testoNormale" v-if="this.profSolenne"><strong>Prof. solenne:</strong>&nbsp;&nbsp;{{ this.profSolenne }}</div>
        <div class="testoNormale" v-if="this.ordSacerdotale"><strong>Ord. sacerdotale:</strong>&nbsp;&nbsp;{{ this.ordSacerdotale }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolbarTop from '../views/ToolbarTop.vue';
import { mapState } from 'vuex';
import axios from 'axios'

export default {
  name: 'FratioComunita',
  components: { ToolbarTop },
  data() {
    return {
      frateTrovato: false,
      nomeCognome: null,
      titoloGiurisdizione: null,
      funzione: null,
      curriculum: null,
      inCorso: null,
      email: null,
      cellulare: null,
      dataNascita: null,
      luogoNascita: null,
      onomastico: null,
      profSemplice: null,
      profSolenne: null,
      ordSacerdotale: null,
      image: null,
      mesi: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
    }
  },
  computed: {
    ...mapState(['frati', 'fratiOk']),
  },
  watch: {
    frati() {
      this.searchFrate();
    },
  },
  methods: {
    searchFrate() {
      console.log(' type of frati = ', typeof this.$store.state.frati)
      console.log(' fichier dei frati = ', this.$store.state.frati)
      for (const [key, frate] of Object.entries(this.$store.state.frati)) {
        console.log(`${key}:` + frate[0].nome);
        if ( frate[0].cognome == this.$route.params.frateComunita ) {
          this.nomeCognome = frate[0].nome + " " + frate[0].cognome.toUpperCase();
          this.titoloGiurisdizione = frate[0].titolo.charAt(0).toUpperCase() + frate[0].titolo.slice(1) + " della " + frate[0].giurisdizione;
          if ( frate[0].funzione ) { this.funzione = frate[0].funzione; }
          if ( frate[0].curriculum ) { this.curriculum = frate[0].curriculum; }
          if ( frate[0].in_corso ) { this.inCorso = frate[0].in_corso; }
          if ( frate[0].ord_sac ) { this.ordSacerdotale = frate[0].ord_sac.substr(8, 2) + " " + this.mesi[parseInt(frate[0].ord_sac.substr(5, 2)) - 1] + " " + frate[0].ord_sac.substr(0, 4); }
          if ( frate[0].onomastico ) { this.onomastico = frate[0].onomastico.substr(3, 2) + " " + this.mesi[parseInt(frate[0].onomastico.substr(0, 2)) - 1]; }
          else { this.onomastico = "" }
          this.email = frate[0].email;
          this.cellulare = frate[0].cellulare;
          this.dataNascita = frate[0].data_nascita.substr(8, 2) + " " + this.mesi[parseInt(frate[0].data_nascita.substr(5, 2)) - 1] + " " + frate[0].data_nascita.substr(0, 4);
          this.luogoNascita = frate[0].luogo_nascita;
          this.profSemplice = frate[0].prof_semp.substr(8, 2) + " " + this.mesi[parseInt(frate[0].prof_semp.substr(5, 2)) - 1] + " " + frate[0].prof_semp.substr(0, 4);
          this.profSolenne = frate[0].prof_sol.substr(8, 2) + " " + this.mesi[parseInt(frate[0].prof_sol.substr(5, 2)) - 1] + " " + frate[0].prof_sol.substr(0, 4);
          this.frateTrovato = true;
          // search for image
          console.log( 'image ', frate[2] )
          axios.get( frate[2].image )
            .then(response => {
              this.image = response.data.source_url;
              console.log( 'image = ', this.image.source_url )
            }
          )
        } 
      }
    }
  },  
  created() {
    // Load Frati
    if ( !this.$store.state.fratiOk ) {
      this.$store.dispatch("getFrati"); }
    else {
      this.searchFrate();
    }
  }
} 
</script>