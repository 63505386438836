<template>
    <div class="topToolbars">

        <!--***************************** 
        *** NAVIGATION BAR AT THE TOP *** 
        *** ************************* ***-->
        <Button class="sticky navbar">

            <!-- BUTTON BACK -->
            <div class="leftButtonsBlock">
                <router-link :to="url" style="display: flex; align-items: center;">
                    <i class="pi pi-chevron-left backArrow"></i>
                    <div>&nbsp;{{ backLabel }}</div>
                </router-link>
            </div>

            <!-- TITLE -->
            <div class="toolbarLabel">{{ title }}</div> 
            <div class="toolbarright"></div> 
        </Button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from 'primevue/button';

export default {
    name: "ToolbarTop",
    components: { Button },
    props: ['url', 'backLabel', 'title'],
	data() {
		return {
            titre: "",
        }
	},
    computed: {
        ...mapState(['titleDisplay'])
    },
    watch: {
        titleDisplay () {
            this.titre = this.$store.state.titleDisplay;
        }
    },
    methods: {
        goBack() { this.$router.back(); },
    },
}
</script>

