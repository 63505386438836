import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'
import Store from './store'
import './assets/css/style.css'
import 'primeicons/primeicons.css';

const app = createApp(App)
app.use(Store)
app.use(Router)
//app.use(PrimeVue)
//app.use(i18n)
//app.directive('tooltip', Tooltip)

app.mount('#app')
