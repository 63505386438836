import { createStore } from 'vuex'
import axios from 'axios'
import { decodeHtml, removeReturn, escapeHTML, convertQuote } from '../assets/js/customJS.js'

export default createStore({
  state: {
    params: null,
    paramsOk: false,
    membri: null,
    membriOk: false,
    frati: [],
    fratiOk: false,
   rootUrl: "https://lavigna.cpv-ofmconv.org",
  },
  mutations: {
    SET_PARAMS( state, val ) { state.params = val; },
    SET_PARAMS_OK( state, val ) { state.paramsOk = val; },

    SET_MEMBRI( state, val ) { state.membri = val; },
    SET_MEMBRI_OK( state, val ) { state.membriOk = val; },

    SET_FRATI( state, val ) { state.frati = val; },
    SET_FRATI_OK( state, val ) { state.fratiOk = val; },
  },
  actions: {

    getFrati({ commit, state }) {
      axios.get( state.rootUrl + '/wordpress/wp-json/wp/v2/frati?per_page=100')
        .then(response => {
          var ifrati = response.data;
          console.log('i frati = ', ifrati)
          var obj = [];
          for (const [key, frate] of Object.entries(ifrati)) {
            console.log(`${key}:` + frate.content.rendered);
            var fratiContent = decodeHtml(frate.content.rendered);
            fratiContent = removeReturn(fratiContent);
            var acf = convertQuote( frate.acf.frati)
            var frateRecord = '[' + fratiContent + ', { "acf" : "' + escapeHTML(acf) + '" }, { "image" : "' + frate._links['wp:featuredmedia'][0].href + '" }]';
            obj.push(JSON.parse(frateRecord))
          }
          console.log('obj = ', obj)
          // sort the frati
          console.log('frati avant sort', obj)
          obj.sort((a, b) => a[0].nome.localeCompare(b[0].nome));
          commit( 'SET_FRATI', obj)
          commit( 'SET_FRATI_OK', true );
          console.log('frati après axios', state.frati )
        }
      )
    },

    getParams({ commit, state }) {
      axios.get( state.rootUrl + '/wordpress/wp-json/wp/v2/pages?slug=parametri')
        .then(response => {
            var paramsBrut = decodeHtml(response.data[0].content.rendered);
            paramsBrut = removeReturn(paramsBrut) ;
            commit( 'SET_PARAMS', JSON.parse(paramsBrut) )
            commit( 'SET_PARAMS_OK', true );
            console.log( state.params )
        }
      )
    },
  }
})
