import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import AnnuarioComunita from '../views/AnnuarioComunita.vue'
import FrateComunita from '../views/FrateComunita.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/annuario',
    name: 'AnnuarioComunita',
    component: AnnuarioComunita,
  },
  {
    path: '/annuario/:frateComunita',
    name: 'FrateComunita',
    component: FrateComunita,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
