<template>
  <div class="paddingTopPage centre" v-if="this.$store.state.fratiOk && this.$store.state.paramsOk">
    <ToolbarTop url="/" backLabel="Home"></ToolbarTop>
    <div class="title">{{ this.titolo }}</div>
    <div v-for="(item, index) in this.$store.state.frati" :key="index" >
      <router-link :to="{ name: 'FrateComunita',  params: { frateComunita: item[0].cognome } }">
        <div class="card">
          {{ item[0].nome }} {{ item[0].cognome.toUpperCase() }}
          <span class="cardFunzione" v-if="item[0].funzione"> ({{ item[0].funzione }})</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import ToolbarTop from '../views/ToolbarTop.vue';
import { mapState } from 'vuex';

export default {
  name: 'AnnuarioComunita',
  components: { ToolbarTop },
  data() {
    return {
      titolo: "",
    }
  },
  computed: {
    ...mapState(['frati', 'fratiOk', 'params', 'paramsOk']),
  },
  watch: {
    paramsOk() {
      this.titolo = this.$store.state.params.menu.annuario
      console.log('titolo = ', this.titlo )
    }
  },
  created() {
    // Load params
    if ( !this.$store.state.paramsOk ) {
      this.$store.dispatch("getParams"); }
    else {
      this.titolo = this.$store.state.params.menu.annuario
    }
    // Load Frati
    if ( !this.$store.state.fratiOk ) {
      this.$store.dispatch("getFrati");
    }
  }
} 
</script>