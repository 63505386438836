/*function show(idName, num) {
    jQuery('div[id^="' + idName + '-"]').css('display', 'none');
    jQuery('div[id="' + idName + '-' + num + '"]').css('display', 'block');
}*/
// Scroll smoothly to top
/*function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
}*/
/*function program(num) {
    jQuery('span[class^="date-"]').css('background-color', '#3c94ba');
    show('saut', num);
    jQuery('.date-' + num).css('background-color', 'red');
}*/
export function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
export function removeReturn(stringa) {
    return stringa.replace(/(\r\n|\n|\r)/gm, "");
}
export function escapeHTML(unsafeText) {
    let div = document.createElement('div');
    div.innerText = unsafeText;
    return div.innerHTML;
}
export function convertQuote(stringa) {
    return stringa.replace(/"/g, "'");
}
